import * as React from "react";
import { getTextColour } from "../utils";
import { Colours } from "../../../assets/global/Theme-variable";
import { Box,Link, Typography } from '@mui/material';

const Tag =({tagRef, colour, label}) =>{
	return (
		<Box key={JSON.stringify(tagRef)} sx={{display: 'flex',
			alignItems: 'center',
			height: '24px',
			margin: '2px',
			lineHeight: '22px',
			backgroundColor: `${colour}`,
			border: `1px solid ${colour}`,
			borderRadius: '4px',
			boxSizing: 'content-box',
			padding: '2px 6px 2px 6px',
			outline: 0,
			// overflow: 'hidden',
		  width:'max-content',
			'&:focus': {
			  borderColor: `${colour}`,
			  backgroundColor:`${colour}`
			},
		  
			// '& span':{
			//   overflow: 'hidden',
			//   whiteSpace: 'nowrap',
			// },
		  
			// '& svg': {
			//   fontSize: '12px',
			//   cursor: 'pointer',
			//   padding: '4px',
			//   color: 'white'
			// }
		  }}>
			<Typography
				sx={{
					fontSize: 10,
                    whiteSpace: 'nowrap',

					color:
						colour === "#2a9ec5"
							? "white"
							: getTextColour(colour).colour,
				}}
			>
				{label}
			</Typography>
		</Box>
	);
}


export const ExpandingTagCell =({ value, width }) => {
    const [expanded, setExpanded] = React.useState(false);
  const numTags = value?.length;


    function getTags() {
        let totalWidth = width - 6 ;
    let tags2 = []
    for (var i = 0; i < value?.length; i++) {
        if(value[i]!== undefined && (totalWidth -(value[i]?.policy_name?.length * 7 ) >= 0 || totalWidth -(value[i]?.tag_name?.length * 7 ) >= 0)) {
        tags2.push(value[i])
        }
        totalWidth -= value[i]?.policy_name ? (value[i]?.policy_name?.length * 7 ) : (value[i]?.tag_name?.length * 7 )
    
    }
    return tags2
    }

    function getWidth() {
        let totalWidth = 0;
    let tags2 = []
    for (var i = 0; i < value?.length; i++) {
        // if(value[i]!== undefined && (totalWidth -(value[i]?.policy_name?.length * 7 + 10) >= 0 || totalWidth -(value[i]?.tag_name?.length * 7 + 10) >= 0)) {
        // tags2.push(value[i]?.policy_name?.length * 7 + 10)
        // }
        totalWidth += value[i]?.policy_name ? (value[i]?.policy_name?.length * 7) : (value[i]?.tag_name?.length * 7 )
    
    }
     totalWidth -= 6
     return totalWidth
    }
    
    
    let limitTags = getTags().length

React.useEffect(()=>{
    console.log('here')
    if(width -6 > getWidth() && expanded) {
        console.log('here')

        setExpanded(false)
    }
},[width])

  return (
    <Box  sx={{
        display: 'flex',
        flexDirection: !expanded ?'row' : 'column',
        justifyContent:'flex-start',
        alignItems:'flex-start',
        // // gap:1,
        // flexWrap: expanded ? 'wrap' : 'nowrap',
        height: expanded ? 'unset' : '100%',
        // overflow: 'hidden',
        width:width
      }}>
      <Box   sx={{
                display: 'flex',
                alignItems: 'center',
                // gap:1,
                flexWrap: expanded ? 'wrap' : 'nowrap',
                // height: expanded ? 'unset' : '100%',
                overflow: 'hidden',
                maxWidth: !expanded && numTags - limitTags !== 0 ? width - 6 : width
              }}>
        {expanded ? value
				?.map(
		
					(option, index) =>
						option !== undefined && (
							<Tag
								label={
									option?.tag_name ? option?.tag_name : option?.policy_name
								}
								tagRef={option}
								key={JSON.stringify(option)}
								colour={
									option?.tag_details
										? option?.tag_details?.styling?.colour
										: Colours.gsblue
								}
							/>
						)
				)
 : value.slice(0, limitTags )?.map(
        (option, index) =>
            option !== undefined && (
                <Tag
                    label={
                        option?.tag_name ? option?.tag_name : option?.policy_name
                    }
                    tagRef={option}
                    key={JSON.stringify(option)}
                    colour={
                        option?.tag_details
                            ? option?.tag_details?.styling?.colour
                            : Colours.gsblue
                    }
                />

            )
    )}
     
        </Box>
        {value.length > 1 && (numTags - limitTags !== 0)  && (
          <Link
            type="button"
            component="button"
            sx={{ justifySelf:'flex-start', fontSize: "inherit", m:1, ml:0,                 overflow: 'visible',
            }}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "view less" :  `+${numTags - limitTags}`
            }
          </Link>
        )}
      </Box>
    );
  }

  export default ExpandingTagCell;
