import axios from "axios";
import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { useFetch } from "../views/profile/useFetch";
import { sorter, sorterPlu, contextDeletedFilter } from "../views/datagrids/utils";
import { presetServers } from "../views/grid/data/presetServers";
import { APP_URL } from "../API/apiConfig";
import { useFetch } from "../views/profile/useFetch";
import { useImmer } from "use-immer";

const DataGridContext = React.createContext();

const DataGridContextProvider = (props) => {
	const { getAccessTokenSilently } = useAuth0();
	const { error, loggedInUser, isAuthenticated } = useFetch();

	const [showDeleted, setShowDeleted] = React.useState(false);
	const [variant, setVariant] = useImmer();

	const [data, setData] = useState({
		user: loggedInUser,
		sites: null,
		screens: null,
		items: null,
		pricing: null,
		tags: null,
		majorcategories: null,
		categories: null,
		printers: null,
		staff: null,
		colours: null,
		analysisCategory: null,
		macro: null,
		function: null,
		paymentMethod: null,
		priceband: null,
		presetServers: presetServers,
		modifier: null,
		users: null,
		userTypes: null,
		policies: null,
		history: null,
		// itemsAll: null,
		// sitesAll: null,
		// printersAll: null,
		// screensAll: null,
		// categoriesAll: null,
		// majorcategoriesAll: null,
		// staffAll: null,
		// tagsAll: null,
		// analysisCategoryAll: null,
		// macroAll: null,
		// functionAll: null,
		// paymentMethodAll: null,
		// pricebandAll: null,
		// presetServersAll: presetServers,
		// modifierAll: null,
		// usersAll: null,
		// userTypesAll: null,
		// policiesAll: null,
	});

	const [newData, setNewData] = useState(false);
	const [loading, setLoading] = useState(true);
	const [isRetail, setIsRetail] = useState(false);
	const [noPermission, setNoPermission] = React.useState(false);
	const [userPermission, setUserPermission] = useState(null);
	// let userPermission = {
	// 	name: "",
	// 	user_ref: "",
	// 	permissions: [],
	// 	userTypePermissions: [],
	// };
	const getPermissions = () => {
		if (loggedInUser) {
			let userPermissions = {
				name: "",
				user_ref: loggedInUser?.app_metadata?.user_ref,
				user_type: loggedInUser?.app_metadata?.user_type,
				permissions: [],
				userTypePermissions: [],
			};
			(loggedInUser?.app_metadata?.userType_permissions?.rules || []).forEach(
				(rule) => {
					userPermissions.permissions.push(rule);
				}
			);

			(loggedInUser?.app_metadata?.user_permissions?.rules || []).forEach(
				(rule) => {
					userPermissions.permissions.push(rule);
				}
			);

			// userPermissions.user_ref = loggedInUser?.app_metadata?.user_ref;

			userPermissions.permissions.forEach((rule) => {
				if (!(rule.page instanceof Array)) rule.page = [rule.page];
				if (!(rule.api instanceof Array)) rule.api = [rule.api];
				if (!rule.actions && rule.action) rule.actions = rule.action;
				if (!(rule.actions instanceof Array)) rule.actions = [rule.actions];

				if (rule.page.includes("plu") && !rule.page.includes("items"))
					rule.page.push("items");
				if (rule.page.includes("list") && !rule.page.includes("screens"))
					rule.page.push("screens");
			});
			setUserPermission(userPermissions);
			if(userPermissions?.permissions?.length === 0) {
				setNoPermission(true);
				setLoading(false)
			}
		}
	};
	// console.log(userPermission);

	React.useEffect(() => {
		getPermissions();
	}, [loggedInUser]);


	React.useEffect(() => {
		if (userPermission !== null && noPermission !== true) {
			fetchArticlesSequentially();
		}
		if (newData) {
			setNewData(false);
		}
	}, [isAuthenticated, newData, userPermission]);

	const fetchArticlesSequentially = async () => {
		const dataSets = [
			"site",
			"tag",
			"majorGroup",
			"salesGroup",
			"plu",
			"printer",
			"list",
			"server",
			"palette",
			"analysisCategory",
			"macro",
			"function",
			"paymentMethod",
			"priceband",
			"modifier",
			"user",
			"userType",
			"policy",
		];
		const fetchedDataSets = [];
		// console.log("getting token silently");
		const token = await getAccessTokenSilently();
		// console.log("gotten token silently");

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		for (const data of dataSets) {
			const api = `${APP_URL}/api/pos/getNextSetFromCache/?table=${data}&uid=0&setSize=9999`;
			const response = await axios.get(api, config);
			const res = await response.data.rows;
			fetchedDataSets.push(res);
		}

		setData({
			...data,

			items: contextDeletedFilter(
				sorterPlu(fetchedDataSets[4], "plu_number"),
				"items",
				"plu",
				userPermission
			),
	
			majorcategories: contextDeletedFilter(
				sorter(fetchedDataSets[2], "majorGroup_number"),
				"majorCategories",
				"majorGroup",
				userPermission
			),
		
			categories: contextDeletedFilter(
				sorter(fetchedDataSets[3], "salesGroup_number"),
				"categories",
				"salesGroup",
				userPermission
			),
		
			tags: contextDeletedFilter(
				sorter(fetchedDataSets[1], "tag_ref"),
				"tags",
				"tag",
				userPermission
			),
	
			sites: contextDeletedFilter(
				sorter(fetchedDataSets[0], "site_ref"),
				"sites",
				"site",
				userPermission
			),
		
			screens: contextDeletedFilter(
				sorter(fetchedDataSets[6], "list_number"),
				"screens",
				"list",
				userPermission
			),

			printers: contextDeletedFilter(
				fetchedDataSets[5],
				"printers",
				"printer",
				userPermission
			),
			staff: contextDeletedFilter(
				sorter(fetchedDataSets[7], "server_number"),
				"staff",
				"server",
				userPermission
			).filter((item) => item.server_details.userLevel !== 9),
			colours: fetchedDataSets[8],
			analysisCategory: contextDeletedFilter(
				sorter(fetchedDataSets[9], "analysisCategory_number"),
				"analysisCategories",
				"analysisCategory",
				userPermission
			),
			macro: contextDeletedFilter(
				sorter(fetchedDataSets[10], "macro_number"),
				"macros",
				"macro",
				userPermission
			),
			function: fetchedDataSets[11],
			paymentMethod: contextDeletedFilter(
				sorter(fetchedDataSets[12], "paymentMethod_number"),
				"paymentMethods",
				"paymentMethod",
				userPermission
			),
			priceband: sorter(fetchedDataSets[13], "priceband_number").filter(
				(item) => item.priceband_isDeleted === 0
			),
			modifier: contextDeletedFilter(
				sorter(fetchedDataSets[14], "modifier_number"),
				"modifiers",
				"modifier",
				userPermission
			),
			users: contextDeletedFilter(
				sorter(fetchedDataSets[15], "user_number"),
				"users",
				"user",
				userPermission
			),
			userTypes: contextDeletedFilter(
				sorter(fetchedDataSets[16], "userType_number"),
				"userTypes",
				"userType",
				userPermission
			),
			policies: contextDeletedFilter(
				sorter(fetchedDataSets[17], "policy_number"),
				"policies",
				"policy",
				userPermission
			),
			history: sorterPlu(fetchedDataSets[4], "plu_number").filter(
				(item) => item.plu_isDeleted === 1
			),
		});
		setLoading(false);
	};


	// let userPermission = {
	// 	name: "",
	// 	user_ref: "",
	// 	permissions: [],
	// 	userTypePermissions: [],
	// };

	// if (loggedInUser) {
	// 	// const userTypePermissions =
	// 	// 	loggedInUser?.app_metadata?.userType_permissions?.rules;
	// 	// const userPermissions = loggedInUser?.app_metadata?.user_permissions?.rules;
	// 	// console.log("UT permissions", userTypePermissions);
	// 	// console.log("U permissions", userPermissions);
	// 	// console.log("loggedinuser", loggedInUser);

	// 	(loggedInUser?.app_metadata?.userType_permissions?.rules || []).forEach(
	// 		(rule) => {
	// 			userPermission.permissions.push(rule);
	// 		}
	// 	);

	// 	(loggedInUser?.app_metadata?.user_permissions?.rules || []).forEach(
	// 		(rule) => {
	// 			userPermission.permissions.push(rule);
	// 		}
	// 	);

	// 	userPermission.user_ref = loggedInUser?.app_metadata?.user_ref;

	// 	userPermission.permissions.forEach((rule) => {
	// 		if (!(rule.page instanceof Array)) rule.page = [rule.page];
	// 		if (!(rule.api instanceof Array)) rule.api = [rule.api];
	// 		if (!rule.actions && rule.action) rule.actions = rule.action;
	// 		if (!(rule.actions instanceof Array)) rule.actions = [rule.actions];

	// 		if (rule.page.includes("plu") && !rule.page.includes("items"))
	// 			rule.page.push("items");
	// 		if (rule.page.includes("list") && !rule.page.includes("screens"))
	// 			rule.page.push("screens");
	// 	});
	// }

	// userPermission.permissions.push({
	// 	actions: ["undelete"],
	// 	page: ["*"],
	// 	api: [],
	// 	allow: true,
	// });
	const [siteFilterValue, setSiteFilterValue] = React.useState([]);

	const [priceBandFilterValue, setPriceBandFilterValue] = React.useState([]);

	const [name, setName] = useState("");
	const [nickname, setNickame] = useState("");
	const [role, setRole] = useState("unknown");
	const [photo, setPhoto] = useState("");
	const [email, setEmail] = useState("");


	React.useEffect(() => {
		if (loggedInUser) {
			setNickame(loggedInUser?.nickname);
			setName(loggedInUser?.name);
			loggedInUser?.user_metadata &&
				loggedInUser?.user_metadata.role &&
				setRole(loggedInUser?.user_metadata.role);
			setPhoto(loggedInUser?.picture);
			setEmail(loggedInUser?.email);
		}
	}, [loggedInUser]);

	const [snackbar, setSnackbar] = React.useState({
		open: false,
		message: [],
		duration: 3000,
		severity: "success",
	});
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState({
		message: [],
		duration: 3000,
		severity: "success",
	}); // duration = ms - severity = error, success, info, warning

	const priceBandColors = [
		{ priceband_number: 1, color: "#e6efef" },
		{ priceband_number: 2, color: "#96979933" },
		{ priceband_number: 3, color: "#d4f1f9" },
		{ priceband_number: 4, color: "#028A984D" },
		{ priceband_number: 5, color: "#0294784D" },
		{ priceband_number: 6, color: "#2C9EDC66" },
		{ priceband_number: 7, color: "#F9F9F9" },
		{ priceband_number: 8, color: "#2A9EC533" },
		{ priceband_number: 9, color: "#5E462D4D" },
		{ priceband_number: 10, color: "#54595F4D" },
	];




	return (
		<DataGridContext.Provider
			value={{
				data,
				setData,
				// user,
				noPermission,
				setNoPermission,
				name,
				setName,
				nickname,
				setNickame,
				role,
				setRole,
				email,
				setEmail,
				photo,
				setPhoto,
				// setUser,
				setNewData,
				snackbarMessage,
				setSnackbarMessage,
				snackbarOpen,
				setSnackbarOpen,
				loading,
				variant,
				setVariant,
				setLoading,
				loggedInUser,
				isRetail,
				setIsRetail,
				// userTypePermissions,
				snackbar,
				setSnackbar,
				// userPermissions,
				userPermission,
				siteFilterValue,
				setSiteFilterValue,
				priceBandColors,
				priceBandFilterValue,
				setPriceBandFilterValue,
				showDeleted, setShowDeleted
			}}
		>
			{props.children}
		</DataGridContext.Provider>
	);
};

export { DataGridContext, DataGridContextProvider };
