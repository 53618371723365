import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Colours } from "../assets/global/Theme-variable";
import { StyledTag } from "./StyledTag";
import { Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiContext } from "@mui/x-data-grid";

export const StandardAutoComplete = (props) => {
	const [selectedOptions, setSelectedOptions] = React.useState(
		props.value || []
	);
	const { id, field, value, options, type } = props;
	const ref = useGridApiContext();
	React.useEffect(() => {
		if (value) {
			ref.current.setEditCellValue({
				id,
				field,
				value: selectedOptions.map((val) => val && val?.policy_ref),
			});
			// ref.current.stopCellEditMode({ id, field });
		}
	}, [value, field, id]);
	// console.log(selectedOptions);
	return (
		<Stack spacing={3} sx={{ width: "100%" }}>
			<Autocomplete
				sx={{
					width: "100%",
					"& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
						border: "none",
						// borderColor: "black",
					},
					"& .MuiInput-root::before .MuiInput-root::hover": {
						border: "none",
					},
				}}
				multiple
				variant="outlined"
				id="tags-standard"
				options={options}
				getOptionLabel={(option) =>
					option?.policy_name
						? option?.policy_name
						: type === "priceband"
						? option.priceband_name
						: option?.tag_name
				}
				isOptionEqualToValue={(option, value) => {
					if (option && option?.policy_ref && value && value?.policy_ref) {
						return option?.policy_ref === value?.policy_ref;
					}
					if (
						option &&
						option?.priceband_ref &&
						value &&
						value?.priceband_ref
					) {
						return option?.priceband_ref === value?.priceband_ref;
					}
					return false;
				}}
				value={selectedOptions}
				renderTags={(value, getTagProps) =>
					value?.map((option, index) => {
						return (
							<Chip
								variant="filled"
								label={
									option?.policy_name
										? option?.policy_name
										: type === "priceband"
										? option?.priceband_name
										: option?.tag_name
								}
								size="medium"
								{...getTagProps({ index })}
								key={JSON.stringify(option)}
								deleteIcon={<CloseIcon size={10} />}
								sx={{
									borderRadius: "6px",
									backgroundColor: Colours.gsblue,
									padding: "2px 4px 2px 8px",
									color: "white",
									borderColor: Colours.gsblue,
									// height: "24px",
									// margin: "2px",
									// lineHeight: "22px",
									fontSize: 10,

									"& svg": {
										fontSize: "14px",
										cursor: "pointer",
										// padding: "4px",
										color: "white !important",
									},
									"& .MuiChip-deleteIcon": {
										fontSize: "12px",
									},
								}}
							/>
						);
					})
				}
				onChange={(e, newValue) => setSelectedOptions(newValue)}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						InputProps={{ ...params.InputProps, disableUnderline: true }}

						// label="Multiple values"
						// placeholder="Favorites"
					/>
				)}
			/>
		</Stack>
	);
};
