import React from "react";
import { CustomFooter } from "../data grid files/Footer";
import EditOffIcon from "@mui/icons-material/EditOff";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RestoreIcon from "@mui/icons-material/Restore";
import { alpha, maxWidth, styled, width } from "@mui/system";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import HistoryIcon from "@mui/icons-material/History";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import {
	GridRowModes,
	DataGrid,
	GridToolbarContainer,
	GridActionsCellItem,
	GridRowEditStopReasons,
	useGridApiRef,
	useGridSelector,
	gridPageCountSelector,
	GridFooterContainer,
	GridPagination,
	gridFilterModelSelector,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AccessControl, { checkPermissions } from "../../AccessControl";
import { DataGridContext } from "../../../context/DataGridContext";
import { API_ENDPOINTS, PAGE_TABLE_MAPPING } from "../../../API/apiConfig";
import { Colours } from "../../../assets/global/Theme-variable";
import {
	CustomNoRowsOverlay,
	CustomOverlay,
	appearsOn,
	computeMutation,
	currencyFormatter,
	getModVal,
	getNestedValue,
	getSubField,
	hasNestedKey,
	modiferDataColumns,
	siteOrTag,
} from "../utils";
import { makeStyles } from "@mui/styles";
import {
	Alert,
	Box,
	Button,
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemText,
	ListSubheader,
	Snackbar,
	Stack,
	Typography,
	debounce,
} from "@mui/material";
import CustomColumnMenu from "../data grid files/CustomColumnMenu";
import MuiPagination from "@mui/material/Pagination";
import { LightTooltip } from "../data grid files/CustomComponents";
import GridModal from "./components/Modal";
import CustomToolbar from "./components/Toolbar";
import ConfirmBox from "../data grid files/ConfirmBox";
import { useLocation, useNavigate } from "react-router-dom";
import HistoryModal from "./components/HistoryModal";
import { dataGridStyle } from "../styles";
import GridTooltip from "./components/GridTooltip";
import { useAppContext } from "../../../appContext";
import UpdateIcon from '@mui/icons-material/Update';

export default function DataGridRefactor({
	// columns,
	setMacroModalVisible,
	modalVisible,
	type,
	url,
	setRowID,
	// rows,
	// setRows,
	contextName,
	gridList,
	table,
	// visibleColumns,
	setModalConfig,
	modalConfig,
	setModalVisible,
	// ref,
}) {
	const { getAccessTokenSilently } = useAuth0();
	const [rowModesModel, setRowModesModel] = React.useState({});
	const ref = useGridApiRef({});
	const historyApiRef = useGridApiRef({});

	const getVisibleColumns = () => {
		const visCol = {};
		for (let i = 0; i < gridList.columns.length; i++) {
			const field = gridList.columns[i].field;
			const visible = gridList.columns[i].visible;
			Object.assign(visCol, { [field]: visible });
		}
		return visCol;
	};
	const columns = getVisibleColumns();
	// setVisibleColumns(columns);
	const [columnVisibilityModel, setColumnVisibilityModel] =
		React.useState(columns);
	const [rows, setRows] = React.useState([]);
	const [snackbar, setSnackbar] = React.useState(null);
	const [searchValue, setSearchValue] = React.useState("");
	const [open, setOpen] = React.useState({
		status: false,
		id: null,
		msg: null,
		lists: [],
	});
	const [deleteData, setDeleteData] = React.useState({});
	const { setState, state } = useAppContext();
	const [openDialog, setOpenDialog] = React.useState(false);
	const [historyModalOpen, setHistoryModalOpen] = React.useState({
		open: false,
		row: "",
	});
	const handleCloseSnackbar = () => setSnackbar(null);
	const {
		data,
		setData,
		// filterModel,
		variant,
		setVariant,
		// setFilterModel,
		siteFilterValue,
		priceBandFilterValue,
		priceBandColors,
		userPermission,
	} = React.useContext(DataGridContext);
	const [showDeleted, setShowDeleted] = React.useState(false);

	const navigate = useNavigate();

	const useStyles = makeStyles(() => ({
		headerWrap: {
			whiteSpace: "break-spaces",
			wordWrap: "break-word",
			width: "900px", // adjust this value as needed
		},
	}));
	const classes = useStyles();
	const updateSearchValue = React.useMemo(() => {
		return debounce((newValue) => {
			// console.log(newValue.split(" ").filter((word) => parseInt(word) !== ""));
			ref.current.setQuickFilterValues(
				newValue.split(" ").filter((word) => word !== "")
			);
		}, 50);
	}, [ref]);

	const customFilterModel = useLocation();

	function handleSearchValueChange(event) {
		const newValue = event.target.value;
		setSearchValue(newValue);
		updateSearchValue(newValue);
	}

	function handleClearSearchValueChange(event) {
		setSearchValue('');
		updateSearchValue('');
	}

	const [filterModel, setFilterModel] = React.useState(
		customFilterModel?.state?.customFilterModel
			? customFilterModel?.state?.customFilterModel
			: {
					items: [
						// {
						// 	field: `${table}_isDeleted`,
						// 	operator: "is",
						// 	value: "false",
						// },
					],
			  }
	);
// console.log(filterModel)
	// React.useEffect(() => {
	// 	console.log("here");
	// 	setFilterModel(
	// 		customFilterModel?.state?.customFilterModel
	// 			? customFilterModel?.state?.customFilterModel
	// 			: {
	// 					items: [
	// 						{
	// 							field: `${table}_isDeleted`,
	// 							operator: "is",
	// 							value: "false",
	// 						},
	// 					],
	// 			  }
	// 	);
	// }, [rows]);
	

	const getRows = () => {
		let rows;
		switch (gridList.gridPage) {
			case "screens":
                rows = data[contextName].filter(
                    (screen) =>
                        !screen.hasOwnProperty("list_variant") || screen.list_variant === 0
                );
                break;
            case "analysisCategory":
                rows = data[contextName]
                        .filter(
                            (cat) =>
                                cat.analysisCategory_number!== "0" && cat.analysisCategory_number!== "100"
                        )
                        .sort((a, b) => a[type] - b[type])
                
                break;
            case "pricebands":
                rows = data[contextName]
                        .filter((cat) => cat.priceband_number!== "1")
                        .sort((a, b) => a[type] - b[type])
                
                break;
				case "staff":
					rows = data[contextName]
					.filter((i) => i.server_number !== "419" && i.server_number !== 419)
					.sort((a, b) => a[type] - b[type])
					break;
				default: rows = data[contextName]
		}
		return rows.filter((i)=> i[`${table}_isDeleted`] === 0)
	}

	const getAllRows = () => {
		let rows;
		switch (gridList.gridPage) {
			case "screens":
                rows = data[contextName].filter(
                    (screen) =>
                        !screen.hasOwnProperty("list_variant") || screen.list_variant === 0
                );
                break;
            case "analysisCategory":
                rows = data[contextName]
                        .filter(
                            (cat) =>
                                cat.analysisCategory_number!== "0" && cat.analysisCategory_number!== "100"
                        )
                        .sort((a, b) => a[type] - b[type])
                
                break;
            case "pricebands":
                rows = data[contextName]
                        .filter((cat) => cat.priceband_number!== "1")
                        .sort((a, b) => a[type] - b[type]).filter((i)=> i[`${table}_isDeleted`] === 0)
                
                break;
				case "staff":
					rows = data[contextName]
					.filter((i) => i.server_number !== "419" && i.server_number !== 419)
					.sort((a, b) => a[type] - b[type])
					break;
				default: rows = data[contextName]
		}
		return rows
	}

	React.useEffect(() => {
		if(showDeleted === false) {
			setRows(getRows())
		} else {			setRows(getAllRows())
		}

	}, [gridList.gridPage, contextName, data]);


	const handleRowEditStop = (params, event) => {
		if (params.reason === GridRowEditStopReasons.rowFocusOut) {
			event.defaultMuiPrevented = true;
		}
	};

	const renderMultiDeleteDialog = (id) => {
		let variants = []
let screens = []
if(gridList.gridPage === 'screens') {
		 screens = rows?.filter((row) =>
			selectedRowsData?.includes(parseInt(row?.uid))
		);
screens?.forEach((screen) => {
	variants.push([...data.screens?.filter((i) =>
		parseInt(i?.list_number) === parseInt(screen?.list_number) && i?.list_isDeleted === 0 && i?.list_variant !== 0
	)])
})
	}

		return (
			<Dialog
				open={!!openDialog}
				// fullWidth
				maxWidth="sm"
				scroll="body"
				onClose={() => setOpenDialog(!openDialog)}
				onBackdropClick={() => setOpenDialog(!openDialog)}
			>
				<DialogContent sx={{ px: 4, py: 4, position: "relative" }}>
					<IconButton
						size="medium"
						onClick={() => setOpenDialog(!openDialog)}
						sx={{ position: "absolute", right: "1rem", top: "1rem" }}
					>
						X
					</IconButton>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Box
								sx={{
									// mb: 3,
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
								}}
							>
								<Typography sx={{ mb: 1 }} variant="h2">
									Delete Rows
								</Typography>

								<Typography variant="body1">
									{gridList.gridPage !== 'screens' ? 'Are you sure you want to delete the selected row(s)?' : 'Are you sure you want to delete the selected screen(s)? Deleting these screens will also delete their associated variants:'}
								</Typography>
								{gridList.gridPage === 'screens' && variants?.length > 0 && 
   <List
   sx={{
	 width: '100%',
	 maxWidth: 360,
	 bgcolor: 'background.paper',
	 position: 'relative',
	 overflow: 'auto',
	 maxHeight: 300,
	 '& ul': { padding: 0 },
   }}
   subheader={<li />}
 >								{screens?.map((screen, i) => 
								<>
								 <li key={`section-${screen.uid}`}>
          <ul>
          {variants[i].length > 0 &&  <ListSubheader>{screen.list_name}</ListSubheader>}
	
									{variants[i]?.map((i) => (
  <ListItem sx={{ p:0}}  key={`item-${screen.list_number}-${i}`}>
  <ListItemText sx={{fontSize: 12,}} inset primary={i.list_name} />
</ListItem>
))}
</ul>
</li>										 
								</>)}
								</List>}
							</Box>
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								gap: "1rem",
							}}
						>
							<Button
								onClick={() => setOpenDialog(!openDialog)}
								size="medium"
								variant="outlined"
								// color="primary"
							>
								Cancel
							</Button>
							<Button
								onClick={handleMultiDeleteConfirmation}
								size="medium"
								variant="contained"
								// color="error"
							>
								Delete
							</Button>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		);
	};

	const renderConfirmDialog = (id) => {
		if (!promiseArguments) {
			return null;
		}
		const { newRow, oldRow } = promiseArguments;

		const handleEntered = () => {
			// The `autoFocus` is not used because, if used, the same Enter that saves
			// the cell triggers "No". Instead, we manually focus the "No" button once
			// the dialog is fully open.
			// noButtonRef.current?.focus();
		};

		return (
			<Dialog
				maxWidth="xs"
				TransitionProps={{ onEntered: handleEntered }}
				open={!!promiseArguments}
			>
				<DialogTitle>Are you sure?</DialogTitle>
				<DialogContent
					dividers
				>{`Pressing 'Yes' will reinstate this row.`}</DialogContent>
				<DialogActions>
					<Button ref={noButtonRef} onClick={handleNo}>
						No
					</Button>
					<Button onClick={handleYes}>Yes</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const processRevert = React.useCallback(
		(newRow, oldRow) =>
			new Promise((resolve, reject) => {
				const mutation = computeMutation(newRow, oldRow);
				if (mutation) {
					// Save the arguments to resolve or reject the promise later
					setPromiseArguments({ resolve, reject, newRow, oldRow });
				} else {
					resolve(oldRow); // Nothing was changed
				}
			}),
		[]
	);
	// const handleEditClick = (id) => () => {
	// 	console.log(id);
	// 	setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
	// };

	const handleSaveClick = (id) => () => {
		// setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.View },
		}));
	};
	const noButtonRef = React.useRef(null);
	const [promiseArguments, setPromiseArguments] = React.useState(null);

	const handleNo = () => {
		const { oldRow, resolve } = promiseArguments;
		resolve(oldRow);
		setPromiseArguments(null);
	};

	const handleYes = async () => {
		const { newRow, oldRow, reject, resolve } = promiseArguments;
		try {
			const response = await handleRevertItem(newRow);
			resolve(response);
			setPromiseArguments(null);
		} catch (error) {
			reject(oldRow);
			setPromiseArguments(null);
		}
	};

	const handleRevertItem = async (id) => {
		// console.log(id)
		const updatedRows = [...data[contextName]];
		// const index = data[contextName].findIndex((i) => parseInt(i[`${table}_uid`]) === parseInt(id));
		const updatedRow = updatedRows?.find(
			(row) => parseInt(row?.[`${table}_uid`]) === parseInt(id)
		);
		// console.log(updatedRow)
		// const variants = [...data[contextName]]?.filter(
		// 	(row) => parseInt(row?.[`${table}_number`]) === parseInt(updatedRow.list_number) && row.list_variant !== 0 && row.list_isCurrent === 1
		// );
		// const toUpdate = [updatedRow, ...variants]

		// console.log([updatedRow, ...variants])
		// toUpdate.forEach((i)=> i[`${table}_isDeleted`] = 0 )
		updatedRow[`${table}_isDeleted`] = 0;

		// updatedRows[index] = updatedRow;
// console.log(table)
		let postStatus = 0;

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: [updatedRow],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
			// updates: [updatedRow],
		};

		axios
			.post(
				`${API_ENDPOINTS[table]}`, // Using config for endpoint
				newRowData,
				config
			)
			.then(function (response) {
				postStatus = response?.status;
				console.log(response.data[table]);
				const newRows = [...data[contextName]]
				const newRow = response.data[table][0];
				newRow.uid = response.data[table][0][`${table}_uid`]
				const index = [...data[contextName]].findIndex((i) => parseInt(i[`${table}_uid`]) === parseInt(id));
		
				newRows[index] = newRow;
				setData({ ...data, [contextName]: newRows });
				// Set the Snackbar message based on the response status
				if (postStatus === 200) {
					setSnackbar({
						children: "successfully reinstated",
						severity: "success",
					});
				}
			})
			.catch(function (error) {
				console.log(error)
				postStatus = error?.response?.status;

				setSnackbar({
					children: `ERROR couldn't save data back to server. ${error?.response?.statusText}.`,
					severity: "error",
				});
			});
		// setRows(updatedRows);
		// setData({ ...data, [contextName]: updatedRows });
		// setFilterModel({
		// 	items: [
		// 		{
		// 			field: `${table}__isDeleted`,
		// 			operator: "is",
		// 			value: "true",
		// 		},
		// 	],
		// });
		// Open the Snackbar
		// setSnackbarOpen(true);

		// // remove anything from the saveOrginalRow for this this current row
		// if (updatedRow.uid in saveOrginalRow.current) {
		// 	delete saveOrginalRow.current[updatedRow.uid];
		// }
		// //Return the updated row to update the internal state of the DataGrid
		// return updatedRow;
	};

	const handleEditClick = React.useCallback(
		(params, event) => {
			const permitted = checkPermissions(userPermission, {
	page: gridList.gridPage,
					action: "edit",
					// strict:'false'
					strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' ||  gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
				
			});
			// console.log(params?.row?.row?.user_number, userPermission?.user_ref);
			if (
				permitted === true &&
				params?.row?.row?.user_number !== userPermission?.user_ref && 
			 params?.row?.row?.userType_number !== userPermission?.user_type
			) {
				// console.log("here permitted");
				if (
					event &&
					params &&
					params.row !== undefined &&
					params?.row?.row?.tag_details?.isEditable !== false
				) {
					// Close any open rows
					// const updatedRowModesModel = {};
					// Object.keys(rowModesModel).forEach((key) => {
					// 	updatedRowModesModel[key] = { mode: GridRowModes.View };
					// });
					// setRowModesModel(updatedRowModesModel);

					// console.log(updatedRowModesModel);
					if (
						typeof gridList.modalEdit === "undefined" ||
						gridList.modalEdit === false
					) {
						console.log("here");
						// setRowModesModel({
						// 	...rowModesModel,
						// 	[params.id]: { mode: GridRowModes.Edit },
						// });
						setRowModesModel(
							// (oldModel) =>
							{
								// ...oldModel,
								[params.id]: { mode: GridRowModes.Edit },
							}
						);
						// Open the clicked row for editing
						// const foundItem = cloneDeep(
						// 	data[contextName]?.find((item) => item?.uid === params?.id)
						// );
						// updatedRowModesModel[params?.id] = {
						// 	mode: GridRowModes.Edit,
						// 	data: foundItem,
						// };
						// saveOrginalRow.current[params?.id] = cloneDeep(
						// 	data[contextName]?.find((item) => item?.uid === params?.id)
						// );
						// console.log(
						// 	"saveOrginalRow",
						// 	JSON.stringify(saveOrginalRow.current)
						// );
					} else {
						// Handle your modal logic
						if (params.id) {
							setModalConfig({
								focusField: "",
								editRow: params.row,
								timestamp: new Date(),
								isNew: false,
								// open: true,
							});
							setModalVisible(true);
						}
					}
				}
			}
			// console.log("hrerer");
		},
		[rowModesModel, setRowModesModel, gridList]
	);

	// const handleEditClick = React.useCallback(
	// 	(params, event) => {
	// 		const permitted = checkPermissions(userPermission, {
	// 			page: gridList.gridPage,
	// 			action: "edit",
	// 			strict: false,
	// 		});
	// 		if (
	// 			permitted === true &&
	// 			params?.row?.user_number !== userPermission?.user_ref
	// 		) {
	// 			if (
	// 				event &&
	// 				params &&
	// 				params.row !== undefined &&
	// 				params?.row?.tag_details?.isEditable !== false
	// 			) {
	// 				// Close any open rows
	// 				const updatedRowModesModel = {};
	// 				Object.keys(rowModesModel).forEach((key) => {
	// 					updatedRowModesModel[key] = { mode: GridRowModes.View };
	// 				});

	// 				if (
	// 					typeof gridList.modalEdit === "undefined" ||
	// 					gridList.modalEdit === false
	// 				) {
	// 					console.log("here");
	// 					setRowModesModel({
	// 						...rowModesModel,
	// 						[params.id]: { mode: GridRowModes.Edit },
	// 					});
	// 				} else {
	// 					// Handle your modal logic
	// 					if (params.id) {
	// 						console.log("here", params);
	// 						if (params.id) {
	// 							console.log("here");
	// 							setModalConfig({
	// 								focusField: "",
	// 								editRow: params.row,
	// 								timestamp: new Date(),
	// 								isNew: false,
	// 								// open: true,
	// 							});
	// 							setModalVisible(true);
	// 						}
	// 					}
	// 				}

	// 				setRowModesModel(updatedRowModesModel);
	// 			}
	// 		}
	// 		// console.log("hrerer");
	// 	},
	// 	[rowModesModel, data, gridList, setModalConfig, setModalVisible]
	// );

	const openDelete = (row) => () => {
		const ids = [row.id]
		const appears = appearsOn(data.screens, row.row.plu_number);
		let variants
		let variantUIDs = []
			if(gridList.gridPage === 'screens') {
				variants = data.screens?.filter((i) =>
					parseInt(i?.list_number) === parseInt(row?.row?.list_number) && i?.list_isDeleted === 0 && i?.list_variant !== 0
				)
				// console.log(variants)
			 variants.map((i) => ids.push(parseInt(i?.list_uid)))
				// console.log(variantUIDs)
			}

		setOpen({
			status: true,
			id: ids,
			msg:
				gridList.gridPage === "items" && appears.length > 0
					? `Deleting this item will affect buttons on the following screens:`
					: gridList.gridPage === "screens" && variants.length > 0
					? `Deleting this screen will also delete it's associated variants:`: null,
			lists:
				gridList.gridPage === "items" && appears.length > 0 ? appears :gridList.gridPage === "screens" && variants.length > 0 ? variants : null,
		});


		// setDeleteData(data);
		// console.log(deleteData)

	};

	const handleDelete = async (id) => {
		let deletedRows = [...data[contextName]]?.filter((row) =>
			id.includes(parseInt(row?.uid))
		);
		setOpen({ status: false, id: "", msg: null, lists: [] });

const table = PAGE_TABLE_MAPPING[gridList.gridPage]; // Get the table name from the mapping

if(variant !== undefined && deletedRows.find((row)=> row.uid === variant?.uid)){
	setVariant((draft)=> {draft.list_isDeleted = 1})
	deletedRows.filter((row)=> row.uid !== variant?.uid).forEach((row) => {
		row[table + "_isDeleted"] = 1;
	})

	// deletedRows[variantInd] = variant
	// console.log(deletedRows)
	// deletedRows.push(variant);
} else {
		deletedRows.forEach((row) => {
			row[table + "_isDeleted"] = 1;
		});
	}
		let postStatus = 0;

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: deletedRows,
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.post(
				`${API_ENDPOINTS[table]}`, // Using the endpoint from API_ENDPOINTS
				newRowData,
				config
			)
			.then(function (response) {
				postStatus = response?.status;
				if (postStatus === 200) {
					setSnackbar({
						children: `Successfully deleted the selected row${
							id.length === 1 ? "" : "s"
						}`,
						severity: "success",
						duration: 4000,
					});
					setSelectedRowsData([])
				}
				setVariant()
				setTimeout(()=> { 
				if(showDeleted === false) {
					setRows(getRows())
				} else {			setRows(getAllRows())
				}
			},[1000])

			})
			.catch(function (error) {
				// postStatus = error?.response?.status;
				console.log(error, postStatus);

				setSnackbar({
					children: `Couldn't delete requested row(s), please try again`,
					severity: "error",
					duration: 8000,
				});
setVariant()
			deletedRows.forEach((row) => {
				row[table + "_isDeleted"] = 0;
			});
			});


			// setSelectedRowsData([])
		// checkPermissions(userPermission, {
		// 	page: gridList.gridPage,
		// 	// allow: true,
		// 	action: "undelete",
		// 	strict: true,
		// }) === true && setRows(newrows);
		// add timeout
		// checkPermissions(userPermission, {
		// 	page: gridList.gridPage,
		// 	// allow: true,
		// 	action: "undelete",
		// 	strict: true,
		// }) === false && setData({ ...data, [contextName]: updatedRows });
		// const isFiltered =
		// 	filterModel.items[0].field === `${table}_isDeleted` &&
		// 	filterModel.items[0].operator === `is` &&
		// 	(filterModel.items[0].value === `true` ||
		// 		filterModel.items[0].value === "");
		// console.log(isFiltered);
		// if (isFiltered === false) {
		// 	setFilterModel({
		// 		items: [
		// 			{
		// 				field: `${table}_isDeleted`,
		// 				operator: "is",
		// 				value: "false",
		// 			},
		// 		],
		// 	});
		// }
	};

	const handleDeleteClick = (id) => () => {
		handleDelete(id);
	};

	const handleCancelClick = (id) => () => {
		setRowModesModel((oldModel) => ({
			...oldModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		}));

	
		const editedRow = rows.find((row) => parseInt(row.uid) === id);
		if (editedRow?.isNew) {
			setRows(rows.filter((row) => parseInt(row.uid) !== id));
		}
	};

	const processRowUpdate = async (newRow, originalRow, isNew) => {
		let updatedRow = { ...newRow, isNew: false };
		const collapseFieldData = [];
		let fieldLevel0 = "";
		let fieldLevel1 = "";

		Object.keys(updatedRow).forEach(function (key) {
			if (key.substring(0, 14) === "expandedfield:") {
				// find the field name
				const findComma = key.search(",");
				const fieldName = key.substring(14, findComma);
				// extract the source field
				const fieldLevelSpliter = fieldName.search("/");
				fieldLevel0 = fieldName.substring(0, fieldLevelSpliter);
				fieldLevel1 = fieldName.substring(fieldLevelSpliter + 1);
				// check the field has a value (i.e. not undefined)
				if (typeof updatedRow[key] !== "undefined") {
					let modValue = null;
					switch (typeof updatedRow[key]) {
						case "number":
							modValue = updatedRow[key];
							break;
						case "string":
							modValue = JSON.stringify(parseFloat(updatedRow[key]));
							break;
						default:
							modValue = null;
					}
					//	if (typeof modValue === "number") {
					const fieldValue =
						"{" +
						key.substring(findComma + 1) +
						',"modifier":' +
						modValue +
						"}";
					// Check if the ID is unique before pushing
					//console.log("JSON", fieldValue, typeof modValue);
					if (
						modValue !== "null" &&
						modValue !== null &&
						typeof modValue !== "undefined"
					) {
						collapseFieldData.push(JSON.parse(fieldValue));
					}
				}
			}
		});

		if (fieldLevel0 !== "" && fieldLevel1 !== "") {
			updatedRow[fieldLevel0][fieldLevel1] = collapseFieldData;
		}

		const token = await getAccessTokenSilently();

		const newRowData = {
			updates: Array.isArray(newRow) ? newRow : [newRow],
		};

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		let postStatus;
		axios
			.post(API_ENDPOINTS[table], newRowData, config)
			.then(function (response) {
				// postStatus = response.status;
				console.log(response);
				if (response.status === 200) {
					let updatedData;
					if (isNew === true) {
						updatedRow = response?.data[table][0];
						updatedData = {
							...data,
							[contextName]: [...data[contextName], updatedRow],
						};
					} else {
						updatedData = {
							...data,
							[contextName]: data[contextName].map((row) =>
								row[`${table}_uid`] === updatedRow[`${table}_uid`]
									? updatedRow
									: row
							),
						};
					}
					setData(updatedData);
		
					setSnackbar({
						children: "successfully saved",
						severity: "success",
					});
				} else {
					setSnackbar({
						children: "Error, couldn't save data",
						severity: "error",
					});
				}
			})
			.catch(function (error) {
				postStatus = error?.response?.status;
				console.log(error?.response?.data?.error);
				setSnackbar({
					children: error?.response?.data?.error
						? error?.response?.data?.error + " - couldn't save data"
						: "Error - couldn't save data",
					severity: "error",
				});
			});

		return updatedRow;
	};

	const handleRowModesModelChange = (newRowModesModel) => {
		setRowModesModel(newRowModesModel);
	};

	const [selectedRowsData, setSelectedRowsData] = React.useState([]);

	const onRowsSelectionHandler = (ids) => {
		if(gridList.gridPage === 'screens') {
			let screens = []
			let variants = []
			let uids = []
			screens = rows?.filter((row) =>
				ids?.includes(parseInt(row?.uid))
			);
	screens?.forEach((screen) => {
		variants.push([...data.screens?.filter((i) =>
			parseInt(i?.list_number) === parseInt(screen?.list_number) && i?.list_isDeleted === 0 && i?.list_variant !== 0
		)])
	})
	variants?.map((variant)=> variant.map((v)=>  uids.push(parseInt(v?.list_uid))))
	ids = [...ids, ...uids]

		} 
		setSelectedRowsData(ids);
		
	};

	const handleNavigate = (item) => () => {
		if (gridList.gridPage === "screens") {
			setVariant()
			navigate("/designer", {
				state: { item: item.row },
			});
		} else if (gridList.gridPage === "items") {
			console.log(item);
			navigate(`/items/${item.row.plu_number}`, {
				state: { item: item.row },
			});
		} else if (gridList.gridPage === "sites") {
			let id = item.id;

			navigate(`/sites/${id}`, {
				state: { item: item.row },
			});
		}
	};

	//
	const dataColumnsBuilder = [];
	for (let i = 0; i < gridList?.columns?.length; i++) {
		if (gridList.columns[i].visible === true) {
			if (gridList.columns[i].type === "priceModifier") {
				const newColumns = modiferDataColumns(
					gridList.columns[i],
					rows,
					data,
					classes,
					ref,
					priceBandColors,
					siteFilterValue,
					priceBandFilterValue,
					priceBandFilterValue
				);
				// console.log(newColumns);
				if (newColumns.length > 0) {
					dataColumnsBuilder.push(...newColumns);
				}
			} else {
				dataColumnsBuilder.push(gridList.columns[i]);
			}
		}
	}

	dataColumnsBuilder.push({
		field: "Actions",
		hideable: false,
		type: "actions",
		headerName: "Actions",
		flex: gridList.gridPage !== "pricing" && 1,
		// width: 50,
		// minWidth: gridList.gridPage === "pricing" && 100,
		cellClassName: "actions",
		allowNavigate: gridList.allowNavigate,
		getActions: (row) => {
			const isRowInEditMode = rowModesModel[row.id]?.mode === GridRowModes.Edit;

			const key = Object.keys(rowModesModel)[0];
			const isInEditMode = rowModesModel[key]?.mode === GridRowModes.Edit;
			if (row?.row[`${table}_isDeleted`] === 1) {
				return 	[actionOptions(row, gridList, "History")];

				// [actionOptions(row, gridList, "Reinstate")];

			}
			if (row?.row?.user_number === userPermission?.user_ref || row?.row?.userType_number === userPermission?.user_type){
				return [actionOptions(row, gridList, "NoEdit")];
			} 
			if (isRowInEditMode) {
				return [
					actionOptions(row, gridList, "Save"),
					actionOptions(row, gridList, "Cancel"),
				];
			}
			if (gridList.allowNavigate !== true) {
				// console.log("not allow navigate");
				if (
					typeof gridList.rowEditingField === "undefined" ||
					getNestedValue(row.row, gridList.rowEditingField)
				) {
					if (isInEditMode) {
						return [actionOptions(row, gridList, "Hidden")];
					}
					return [
						actionOptions(row, gridList, "Edit"),
						actionOptions(row, gridList, "Delete"),
						actionOptions(row, gridList, "History"),
					];
				} else {
					return [actionOptions(row, gridList, "NoEdit")];
				}
			} else if (isInEditMode) {
				return [actionOptions(row, gridList, "Hidden")];
			}
			if (
				gridList.gridPage === "screens" &&
				(row.row.list_details.ignoreThisList === true ||
					row.row.list_details.buttons.some((x) => x.functionType === 13))
			) {
				// console.log("page is screens");

				return [actionOptions(row, gridList, "Hidden")];
			} else
				return [
					actionOptions(row, gridList, "Edit"),
					actionOptions(row, gridList, "Delete"),
					actionOptions(row, gridList, "History"),
					actionOptions(row, gridList, "More"),
				];
		},
	});

	const actionOptions = (row, gridList, option) => {
		switch (option) {
			case "Edit":
				return (
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: gridList.gridPage,
							action: "edit",
							// strict: false,
							strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
						}}
						// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
						renderNoAccess={() => <></>}
					>
						{/* <GridActionsCellItem
							icon={
								<>
									<LightTooltip title="Edit">
									<EditIcon fontSize="inherit" />
									</LightTooltip>
								</>
							}
							label="Edit"
							className="tableControl actions"
							onClick={(event) => {
								event.stopPropagation(); // Prevent the row click event from firing
								handleEditClick({ id: row.id, row: row }, event);
							}}
							color="inherit"
						/> */}
						<GridTooltip label="Edit">
							
							<EditIcon
								fontSize="small"
								
								className="tableControl"
								onClick={(event) => {
									event.stopPropagation(); // Prevent the row click event from firing
									handleEditClick({ id: row.id, row: row }, event);
								}}
							/>
						</GridTooltip>
					</AccessControl>
				);

				break;
			case "Delete":
				return (
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: gridList.gridPage,
							action: "delete",
							strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'permissions' || gridList.gridPage === 'configuration'  || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
						}}
						renderNoAccess={() => <></>}
					>
						{/* <GridActionsCellItem
							icon={
								<>
									 <LightTooltip title="Delete">
									<DeleteIcon fontSize="inherit" />
									</LightTooltip>
								</>
							}
							label="Delete"
							// to do
							onClick={openDelete(row)}
							color="inherit"
							className="tableControl"
						/> 
						 */}

						<GridTooltip label="Delete">
							<DeleteIcon
								fontSize="small"
							
								className="tableControl"
								onClick={openDelete(row)}
							/>
						</GridTooltip>
					</AccessControl>
				);
				break;
			case "More":
				return (
					// <GridActionsCellItem
					// 	icon={
					// 		<>
					// 			{/* <LightTooltip title="More"> */}
					// 			<ArrowForwardIcon fontSize="inherit" />
					// 			{/* </LightTooltip> */}
					// 		</>
					// 	}
					// 	label="Go"
					// 	// to do
					// 	onClick={handleNavigate(row)}
					// 	color="inherit"
					// 	className="tableControl"
					// />
					<GridTooltip label="More">
						<ArrowForwardIcon
							fontSize="small"
							
							className="tableControl"
							onClick={handleNavigate(row)}
						/>
					</GridTooltip>
				);
				break;
			case "Cancel":
				return (
					<GridActionsCellItem
						icon={
							<>
								{/* <LightTooltip title="Cancel"> */}
								<CancelIcon fontSize="inherit" />
								{/* </LightTooltip> */}
							</>
						}
						label="Cancel"
						//className="textPrimary"
						// className="tableControl"
						onClick={handleCancelClick(row.id)}
						color="inherit"
					/>
				);
				break;
			case "Save":
				return (
					<GridActionsCellItem
						icon={
							<>
								{/* <LightTooltip title="Save"> */}
								<SaveIcon fontSize="inherit" />
								{/* </LightTooltip> */}
							</>
						}
						label="Save"
						onClick={handleSaveClick(row.id)}
						// className="tableControl"
					/>
				);
				break;
			case "NoEdit":
				return (
					<GridActionsCellItem
						icon={
							// <IconButton size="small">
							// <LightTooltip title="Uneditable">
							<EditOffIcon fontSize="inherit" />
							// </LightTooltip>
							// </IconButton>
						}
						label="Uneditable"
						//onClick={handleEditClick(row.id, gridList.modalEdit)}
						color="inherit"
						className="tableControl"
					/>
				);
				break;
			case "Reinstate":
				return (
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: gridList.gridPage,
							// allow: false,
							action: "undelete",
							strict: true,
						}}
						// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
						renderNoAccess={() => <></>}
					>
						{/* <GridActionsCellItem
							icon={
								// <IconButton size="small">
								// <LightTooltip title="Restore">
								<RestoreIcon fontSize="inherit" />
								// </LightTooltip>
								// </IconButton>
							}
							label="Restore"
							color="inherit"
							className="tableControl"
							onClick={(event) => {
								event.stopPropagation();
								processRevert(row.id);
							}}
						/> */}
						<GridTooltip label="Reinstate">
							<UpdateIcon
								fontSize="small"
								
								className="tableControl"
								onClick={(event) => {
									event.stopPropagation();
									processRevert(row.id);
								}}
							/>
						</GridTooltip>
					</AccessControl>
				);
				break;
			case "History":
				return (
					// <AccessControl
					// 	userPermissions={userPermission}
					// 	unallowedPermissions={{
					// 		page: gridList.gridPage,
					// 		// allow: false,
					// 		action: "undelete",
					// 		strict: true,
					// 	}}
					// 	// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
					// 	renderNoAccess={() => <></>}
					// >
					// <GridActionsCellItem
					// 	icon={
					// 		// <IconButton size="small">
					// 		<LightTooltip title="History">
					// 			<HistoryIcon fontSize="inherit" />
					// 		</LightTooltip>
					// 		// </IconButton>
					// 	}
					// 	label="History"
					// 	color="inherit"
					// 	className="tableControl"
					// onClick={(event) => {
					// 	event.stopPropagation();
					// 	// to do
					// 	// processRevert(row.id);
					// 	setHistoryModalOpen({ open: true, row: row });
					// }}
					// />
					<GridTooltip label="History">
						<HistoryIcon
							fontSize="small"
						
							className="tableControl"
							onClick={(event) => {
								event.stopPropagation();
								// to do
								// processRevert(row.id);
								setHistoryModalOpen({ open: true, row: row });
							}}
						/>
					</GridTooltip>
					// </AccessControl>
				);
				break;
			case "Hidden":
				return <></>;

			default:
		}
	};

	const handleMultiCheckBoxDelete = () => {
		setOpenDialog(true);
	};

	const handleMultiDeleteConfirmation = () => {
		handleDelete(selectedRowsData);
		setOpenDialog(false);
		// setSelectedRowsData([]);
	};


	const handleMultiCheckBoxSelect = () => {
	
		if (gridList.checkBoxDelete === true) {
			const displayButton = selectedRowsData.length === 0 ? false : true;
			return (
				<>
					<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: gridList.gridPage,
							// allow: false,
							action: "delete",
							strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'permissions' || gridList.gridPage === 'configuration'  || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
					
						}}
						renderNoAccess={() => <></>}
					>
						{selectedRowsData.length > 0 ? (
							<Button
								onClick={handleMultiCheckBoxDelete}
								disabled={!displayButton}
								startIcon={<CheckBoxIcon color={Colours.gsblue} size={30} />}
								size="medium"
							>
								Delete selected row
								{selectedRowsData.length === 1 ? "" : "s"}
							</Button>
						) : null}
					</AccessControl>
				</>
			);
		}
	};

	const CustomFooter = (props) => {
		const { paginationProps } = props;
		return (
			<GridFooterContainer>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Box>{handleMultiCheckBoxSelect()}</Box>
					<Box>
						<GridPagination
							{...paginationProps}
							ActionsComponent={Pagination}
						/>
					</Box>
				</Box>
			</GridFooterContainer>
		);
	};

	const handleCellKeyDown = React.useCallback((params, event) => {
		if (event.key === "Enter") {
			event.preventDefault();
			event.stopPropagation();
			// setRowModesModel({
			// 	...rowModesModel,
			// 	[params.id]: { mode: GridRowModes.View },
			// });
			setRowModesModel((oldModel) => ({
				...oldModel,
				[params.id]: { mode: GridRowModes.View },
			}));
			console.log(rowModesModel);
		}
	}, []);

	const getTogglableColumns = (columns) => {
		return columns
			.filter(
				(column) =>
					column.field !== "tagExport" &&
					column.field !== "__check__" &&
					column.field !== "Actions" &&
					column.allowToggle !== false
			)
			.map((column) => column.field);
	};

	const toggleShowDeleted = (e) => {
		setShowDeleted(e.target.checked);
if(e.target.checked === true) {

		setRows(getAllRows());
} else {
	setRows(getRows());

}
	};


	function Pagination({ page, onPageChange, className }) {
		const pageCount = useGridSelector(ref, gridPageCountSelector);

		return (
			<MuiPagination
				color="primary"
				className={className}
				count={pageCount}
				page={page + 1}
				onChange={(event, newPage) => {
					onPageChange(event, newPage - 1);
				}}
				size="small"
				shape="rounded"
			/>
		);
	}


	const autosizeOptions = {
		includeOutliers: true,
	  };

	return (
		<
			// elevation={0}
			// sx={{
			// 	"& .MuiDataGrid-root": {
			// 		border: "none",
			// 		height: "100%",
			// 		width: "100%",
			// 		flex: 1,
			// 		overflow: "scroll",
			// 		// maxWidth: "100%",
			// 	},
			// 	margin: 0,
			// 	padding: 0,
			// 	width: gridList.gridPage === "pricing" ? "100%" : "100%",
			// 	height: "100%",
			// 	overflow: "hidden",
			// 	flex: 1,
			// 	// maxWidth: "100%",
			// }}
		>
			{renderConfirmDialog()}
			{renderMultiDeleteDialog()}

			<ConfirmBox
				deleteFunction={handleDeleteClick(open?.id)} // send through the one ID as an array
				open={open}
				gridList={gridList}
				page={gridList.gridPage}
				closeDialog={() => setOpen({
					status: false,
					id: null,
					msg: null,
					lists: [],
				})}
			/>
			<GridModal
				modalConfig={modalConfig}
				gridList={gridList}
				setModalConfig={setModalConfig}
				modalVisible={modalVisible}
				defaultValues={modalConfig?.editRow?.row}
				setModalVisible={setModalVisible}
				processRowUpdate={processRowUpdate}
				contextName={contextName}
				table={table}
			/>

			<HistoryModal
				modalConfig={historyModalOpen}
				gridList={gridList}
				setModalConfig={setHistoryModalOpen}
				processRowUpdate={processRowUpdate}
				contextName={contextName}
				table={table}
				apiRef={historyApiRef}
			/>
			<DataGrid
				rows={rows}
				columns={dataColumnsBuilder}
				// initialState={{
				// 	pagination: {
				// 		paginationModel: { pageSize: 25 },
				// 	},
				// }}
				initialState={{
					pagination: { paginationModel: { pageSize: 100 } },
					columns: {
						columnVisibilityModel,
					},
				}}
				autosizeOptions={autosizeOptions}

				filterModel={filterModel}
				onFilterModelChange={(newFilterModel) => {
					// console.log("setting filterModel", newFilterModel);
					setFilterModel(newFilterModel);
				}}
				// paginationMode="server"
				// getEstimatedRowHeight={() => 100}

				getRowHeight={() => "auto"}
				// pageSizeOptions={[5, 10, 25, 50]}
				getRowId={(row) => parseInt(row[`${table}_uid`])}
				sx={{
					// ...dataGridStyle,
					"& .firstRow": {
						// display: state.stepIndex === 4 ? null : "none",
						"& .tableControl": {
							display: state.stepIndex === 4 ? "unset" : "none",
						},
					},
				}}
				apiRef={ref}
				// disableRowSelectionOnClick
				showCellVerticalBorder
				showCellHorizontalBorder
				editMode="row"
				columnHeaderHeight={gridList.gridPage === "pricing" ? 90 : 56}
				// headerClassName={classes.wrappedHeader}
				rowModesModel={rowModesModel}
				// onRowModesModelChange={handleRowModesModelChange}
				onRowEditStop={handleRowEditStop}
				columnVisibilityModel={columnVisibilityModel}
				onColumnVisibilityModelChange={(newModel) =>
					setColumnVisibilityModel(newModel)
				}
				onCellKeyDown={handleCellKeyDown}
				density={"compact"}
				// componentsProps={{
				// 	handleClick: handleMultiDelete,
				// }}
				// slots={{
				// 	footer: CustomFooter,
				// }}
				// slotProps={{
				// 	footer: { handleMultiDelete, selectedRowsData },
				// }}
				slots={{
					toolbar: CustomToolbar,
					noRowsOverlay: CustomNoRowsOverlay,
					noResultsOverlay: CustomOverlay,
					footer: CustomFooter,
					loadingOverlay: LinearProgress,
					columnMenu: CustomColumnMenu,
				}}
				getRowClassName={(params) =>
					// params.indexRelativeToCurrentPage === 0
					// 	? "firstRow"
					params?.row[`${table}_isDeleted`] &&
					(params?.row[`${table}_isDeleted`] === 1 ||
						params?.row[`${table}_isDeleted`] === "1")
						? "deletedRow"
						: params.indexRelativeToCurrentPage === 0
						? "firstRow"
						: ""
				}
				getCellClassName={(params) => {
					if (
						params.field === "printer_details.sites" ||
						params.field === "printer_details.site_name" ||
						params.field === "printer_details.site_ref"
					) {
						return "printerDetails";
					}
				}}
				slotProps={{
					toolbar: {
						data,
						modalVisible,
						setModalVisible,
						modalConfig,
						setModalConfig,
						setData,
						setRowModesModel,
						gridList,
						selectedRowsData,
						setSnackbar,
						// page,
						// sitesRef,
						searchValue,
						handleSearchValueChange,
						handleClearSearchValueChange,
						// setImportModalVisible,
						// importModalVisible,
						// setNewItemModalOpen,
						// newItemModalOpen,
						table,
						// exportFields,
						contextName,
						showDeleted,
						filterModel,
						setFilterModel,

						toggleShowDeleted: toggleShowDeleted,
						// priceBandsEnabled: priceBandsEnabled, // Pass the value of priceBandsEnabled
						// handlePriceBandsToggle: handlePriceBandsToggle, // Pass the function handlePriceBandsToggle
					},
					columnsPanel: {
						getTogglableColumns,
					},
					getActions: gridList,
					// handleCheckBoxDelete: handleMultiCheckBoxDelete,
					columnHeader: {
						className: classes.headerWrap,
					},
				}}
				checkboxSelection
				disableRowSelectionOnClick
				onRowDoubleClick={(params, event) => {
					event.stopPropagation();
					// const permitted = checkPermissions(userPermission, {
					// 	page: gridList.gridPage,
					// 	action: "edit",
					// 	strict: false,
					// });
					// if (permitted === true) {
					// 	// console.log("permitted is true");
					handleEditClick({ id: params.id, row: params }, event);
					// } else {
					// }
				}}
				isRowSelectable={(params) => { 
					// console.log(params.row, userPermission)
					if(params?.row?.user_number === userPermission?.user_ref || params?.row?.userType_number === userPermission?.user_type){
					return false
				}else {
					return true}}}

				onRowSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
				processRowUpdate={(updatedRow, originalRow) => {
					// console.log(updatedRow, originalRow);
					if (updatedRow !== originalRow) {
						// return {
						// 	...originalRow,
						// 	newrow: { ...updatedRow },
						// };
						processRowUpdate(updatedRow, originalRow);
					}
					return updatedRow;
				}}
				experimentalFeatures={{ newEditingApi: true }}
			/>
			{!!snackbar && (
				<Snackbar
					open
					anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
					onClose={handleCloseSnackbar}
					autoHideDuration={6000}
				>
					<Alert {...snackbar} onClose={handleCloseSnackbar} />
				</Snackbar>
			)}
		</>
	);
}
