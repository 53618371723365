// apiConfig.js

export const APP_URL = "https://max-suite-demo.gs-systems.co.uk";

const BATCH_UPDATE_BASE_URL =
	"https://max-suite-demo.gs-systems.co.uk/api/pos/batchUpdate";

const NOTES_BASE_URL = "https://max-suite-demo.gs-systems.co.uk/api/Notes";

const EMAIL_BASE_URL = "https://max-suite-demo.gs-systems.co.uk/api/email";

export const API_ENDPOINTS = {
	// Batch updates
	plu: `${BATCH_UPDATE_BASE_URL}/plu`,
	site: `${BATCH_UPDATE_BASE_URL}/site`,
	printer: `${BATCH_UPDATE_BASE_URL}/printer`,
	server: `${BATCH_UPDATE_BASE_URL}/server`,
	majorGroup: `${BATCH_UPDATE_BASE_URL}/majorGroup`,
	salesGroup: `${BATCH_UPDATE_BASE_URL}/salesGroup`,
	list: `${BATCH_UPDATE_BASE_URL}/list`,
	tag: `${BATCH_UPDATE_BASE_URL}/tag`,
	analysisCategory: `${BATCH_UPDATE_BASE_URL}/analysisCategory`,
	macro: `${BATCH_UPDATE_BASE_URL}/macro`,
	palette: `${BATCH_UPDATE_BASE_URL}/palette`,
	function: `${BATCH_UPDATE_BASE_URL}/function`,
	paymentMethod: `${BATCH_UPDATE_BASE_URL}/paymentMethod`,
	priceband: `${BATCH_UPDATE_BASE_URL}/priceband`,
	modifier: `${BATCH_UPDATE_BASE_URL}/modifier`,
	user: `${BATCH_UPDATE_BASE_URL}/user`,
	userType: `${BATCH_UPDATE_BASE_URL}/userType`,
	policy: `${BATCH_UPDATE_BASE_URL}/policy`,

	// Notes URL
	postActionNotes: `${NOTES_BASE_URL}/postAction`,
	getAllNotes: `${NOTES_BASE_URL}/getAllNotes`, // Add getAllNotes endpoint

	saveNotes: `${NOTES_BASE_URL}/saveNotes`,

	//Email URL
	sendFeedbackEmail: `${EMAIL_BASE_URL}/sendFeedback`,
	// add more endpoints as needed
	getHistory: `${APP_URL}/api/pos/getHistoryByIdentifier`,
	marketManPLU: `${APP_URL}/api/pos/orderMarketManSyncPlus/?debug=true`,
	marketManGroups: `${APP_URL}/api/pos/orderMarketManSyncPlus/?debug=true&modifier=sendGroups`,
	marketManAll: `${APP_URL}/api/pos/orderMarketManSyncPlus/?debug=true&modifier=sendAll`,
	// https://max-suite-demo.gs-systems.co.uk/api/pos/orderMarketManSyncPlus/?debug=true&modifier=sendAll
};

// This maps the pages from the datagrid config
export const PAGE_TABLE_MAPPING = {
	pricing: "plu",
	items: "plu",
	sites: "site",
	printers: "printer",
	staff: "server",
	majorcategories: "majorGroup",
	categories: "salesGroup",
	screens: "list",
	tags: "tag",
	users: "user",
	policies: "policy",
	userTypes: "userType",
	macro: "macro",
	paymentMethod: "paymentMethod",
	analysisCategory: "analysisCategory",
	functions: "function",
	modifier: "modifier",
	priceband: "priceband",
	// Add more mappings as needed
};
