import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
// import { DataGridContext } from "./datagrids/data grid files/DataGridContext";
import Spinner from "../components/Spinner/Spinner";

import AccessControl, { checkPermissions } from "./AccessControl";
import { dataGridStyle } from "./datagrids/styles";

import { Link, matchPath, useLocation } from "react-router-dom";
import DataGridRefactor from "./datagrids/DataGridRefactor/DataGrid";
import { Config } from "./datagrids/DataGridRefactor/Config";
import { PAGE_TABLE_MAPPING } from "../API/apiConfig";
import { DataGridContext } from "../context/DataGridContext";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

function useRouteMatch(patterns) {
	const { pathname } = useLocation();

	for (let i = 0; i < patterns.length; i += 1) {
		const pattern = patterns[i];
		const possibleMatch = matchPath(pattern, pathname);
		if (possibleMatch !== null) {
			return possibleMatch;
		}
	}
	return null;
}
export default function Permissions() {
	const {
		data,

		userPermission,
	} = React.useContext(DataGridContext);
	const location = useLocation();
	const routeMatch = useRouteMatch([
		"permissions/users",
		"permissions/userTypes",
		"permissions/policies",
	]);
	const currentTab = routeMatch?.pattern?.path;
	const path = currentTab ? currentTab?.split("/")[1] : checkPermissions(userPermission, {
		page: 'users',
		action: "view",
		strict: true,
	}) === true ? "users" : checkPermissions(userPermission, {
		page: 'userTypes',
		action: "view",
		strict: true,
	}) === true ? "userTypes" : 'policies' ;
	const path2 = path;

	const [type, setType] = React.useState(path);
	// const [contextName, setContextName] = React.useState(
	// 	path === "users" || path === "userTypes" ? path.slice(0, -1) : "policy"
	// );
	const [modalConfig, setModalConfig] = React.useState({
		focusField: "",
		editRow: "",
	});
	const [importModalVisible, setImportModalVisible] = React.useState(false);
	const [modalVisible, setModalVisible] = React.useState(false);
	// const [contextName, setContextName] = React.useState("");
	const [rows, setRows] = React.useState(
		data[path2] !== null && location.pathname.substring(1) === "screens"
			? data[path2]?.filter((screen) => screen?.list_variant === 0)
			: data[path2] !== null
			? data[path2]
			: []
	);
	const [value, setValue] = React.useState(
		type === "users" ? 0 : type === "userTypes" ? 1 : 2
	);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		let type = "";
		let context = "";

		switch (newValue) {
			case 0:
				type = "users";
				context = "users";
				break;
			case 1:
				type = "userTypes";
				context = "userTypes";
				break;
			case 2:
				type = "policies";
				context = "policies";
				break;

			default:
		}
		setType(type);
		// setContextName(context);
	};

	const page = location.pathname.substring(1);

	const table = PAGE_TABLE_MAPPING[path];

	// const pageAdjust = location.pathname.substring(1);
	let gridList = Config(path);

	const getVisibleColumns = () => {
		const visCol = {};
		for (let i = 0; i < gridList.columns.length; i++) {
			const field = gridList.columns[i].field;
			const visible = gridList.columns[i].visible;
			Object.assign(visCol, { [field]: visible });
		}
		return visCol;
	};

	const [visibleColumns, setVisibleColumns] = React.useState(
		getVisibleColumns()
	);
	// console.log(path2);
	return (
		<>
			{data?.macro === null ||
			data?.paymentMethod === null ||
			data?.priceband === null ||
			data?.users === null ||
			data?.userTypes === null ||
			data?.policies === null ||
			data?.modifier === null ||
			data?.function === null ? (
				<Spinner />
			) : (
				<Box
					sx={{
						maxWidth: "100%",
						maxHeight: "100%",
						overflow: "hidden",

						display: "flex",
						flexDirection: "column",
						flex: 1,
						ml: 4,
						mr: 4,
					}}
				>
					<Box
						sx={{
							mb: 2,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example"
						>
								<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: 'users',
							// allow: false,
							action: "view",
							strict: true,
						}}
						renderNoAccess={() => <></>}
					>
							<Tab
								sx={{ paddingBottom: 0 }}
								label="Users"
								{...a11yProps(0)}
								to={"/permissions/users"}
								component={Link}
								value={0}
							/>
							</AccessControl>
							<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: 'userTypes',
							// allow: false,
							action: "view",
							strict: true,
						}}
						renderNoAccess={() => <></>}
					>
							<Tab
								to={"/permissions/userTypes"}
								component={Link}
								value={1}
								sx={{ paddingBottom: 0 }}
								label="User Types"
								{...a11yProps(1)}
							/>
							</AccessControl>
								<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: 'policies',
							// allow: false,
							action: "view",
							strict: true,
						}}
						renderNoAccess={() => <></>}
					>
							<Tab
								sx={{ paddingBottom: 0 }}
								label="Policies"
								to={"/permissions/policies"}
								component={Link}
								value={2}
								{...a11yProps(2)}
							/>
							</AccessControl>
						</Tabs>
					</Box>{" "}
					<Box
						role="tabpanel"
						sx={{
							...dataGridStyle,
							flex: 1,
							width: "100%",
							height: "80%",
							"& .MuiDataGrid-root": {
								border: "none",
								height: "100%",
								// width: "100%",
								flex: 1,
								overflow: "auto",
								whiteSpace: "normal !important",
								wordWrap: "break-word !important",
								fontSize: 13,
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
								// maxWidth: "100%",
							},
							"& .MuiDataGrid-cell": {
								borderBottom: "none",
							},
							mb: 2,
						}}
					>
						<DataGridRefactor
							gridList={gridList}
							page={page}
							modalVisible={modalVisible}
							setModalVisible={setModalVisible}
							modalConfig={modalConfig}
							setModalConfig={setModalConfig}
							rows={rows}
							setRows={setRows}
							visibleColumns={visibleColumns}
							setVisibleColumns={setVisibleColumns}
							importModalVisible={importModalVisible}
							table={table}
							contextName={path2}
							setImportModalVisible={setImportModalVisible}
						/>
					</Box>
				</Box>
			)}
		</>
	);
}
