import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { getTextColour } from "../utils";
import { Colours } from "../../../assets/global/Theme-variable";

const Root = styled("div")(
	({ theme }) => `
    background-color: none;
display: flex;
flex-direction: row;
flex-wrap: wrap;
  font-size: 14px;
  width:'100%';
`
);

const Tag =({tagRef, colour, label}) =>{
	return (
		<Box key={JSON.stringify(tagRef)} sx={{display: 'flex',
			alignItems: 'center',
			height: '24px',
			margin: '2px',
			lineHeight: '22px',
			backgroundColor: `${colour}`,
			border: `1px solid ${colour}`,
			borderRadius: '6px',
			boxSizing: 'content-box',
			padding: '2px 6px 2px 6px',
			outline: 0,
			overflow: 'hidden',
		  
			'&:focus': {
			  borderColor: `${colour}`,
			  backgroundColor:`${colour}`
			},
		  
			'& span':{
			  overflow: 'hidden',
			  whiteSpace: 'nowrap',
			  textOverflow: 'ellipsis'
			},
		  
			'& svg': {
			  fontSize: '12px',
			  cursor: 'pointer',
			  padding: '4px',
			  color: 'white'
			}
		  }}>
			<Typography
				sx={{
					fontSize: 10,
					color:
						colour === "#2a9ec5"
							? "white"
							: getTextColour(colour).colour,
				}}
			>
				{label}
			</Typography>
		</Box>
	);
}

export default function DisplayTagCell(params, item) {
	const limitTags = 3;
	const numTags = params?.value?.length;
	console.log(numTags > limitTags && ` +${numTags - limitTags}`)
	return (
		<Root key={JSON.stringify(params?.value)}>
			{params?.value
				?.slice(0, limitTags)
				.map(
					(option, index) =>
						option !== undefined && (
							<Tag
								label={
									option?.tag_name ? option?.tag_name : option?.policy_name
								}
								tagRef={option}
								key={JSON.stringify(option)}
								colour={
									option?.tag_details
										? option?.tag_details?.styling?.colour
										: Colours.gsblue
								}
							/>
						)
				)}
			{numTags > limitTags && ` +${numTags - limitTags}`}
		</Root>
	);
}
