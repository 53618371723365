// import { MenuItem } from "@mui/base";
import {
	Autocomplete,
	Box,
	Card,
	FormControl,
	IconButton,
	InputLabel,
	Select,
	TextField,
	MenuItem,
	Typography,
} from "@mui/material";
import React from "react";
import PermissionsToggle from "./PermissionsToggle";
import { Colours } from "../assets/global/Theme-variable";
import DeleteIcon from "@mui/icons-material/Delete";
import { SortableList } from "./SortableList";
import { capitalise } from "../views/datagrids/utils";

export default function Rule({
	rule,
	permissionObject,
	handleEditActions,
	handleEditRule,
	handleEditAllow,
	handleRuleDelete,
	error,
}) {
	// console.log(permissionObject);
	const index = permissionObject.findIndex(
		(modifier) => modifier.id === rule.id
	);
	const pages = [
		{
			page: "all",
			dbName: "*",
		},
		{
			page: "screens",
			dbName: "screens",
		},
		{
			page: "items",
			dbName: "items",
		},
		{
			page: "tags",
			dbName: "tags",
		},
		{
			page: "staff",
			dbName: "staff",
		},
		{
			page: "major categories",
			dbName: "majorcategories",
		},
		{
			page: "categories",
			dbName: "categories",
		},
		// {
		// 	page: "configuration",
		// 	dbName: "configuration",
		// },
		{
			page: "pricing",
			dbName: "pricing",
		},
		{
			page: "printers",
			dbName: "printers",
		},
		{
			page: "sites",
			dbName: "sites",
		},
		// {
		// 	page: "permissions",
		// 	dbName: "permissions",
		// },

		{
			page: "users",
			dbName: "users",
		},
		{
			page: "user types",
			dbName: "userTypes",
		},
		{
			page: "policies",
			dbName: "policies",
		},
		{
			page: "macros",
			dbName: "macros",
		},
		{
			page: "pricebands",
			dbName: "pricebands",
		},
		{
			page: "analysis categories",
			dbName: "analysisCategories",
		},
		{
			page: "modifiers",
			dbName: "modifiers",
		},
		{
			page: "payment methods",
			dbName: "paymentMethods",
		},
		{
			page: "settings",
			dbName: "settings",
		},
	];

	const actions  = [ {
		name: "All",
		value: "*",
	},{
		name: "View",
		value: "view",
	},
	{
		name: "Create",
		value: "create",
	},{
		name: "Edit",
		value: "edit",
	},{
		name: "Delete",
		value: "delete",
	},{
		name: "Undelete",
		value: "undelete",
	},

	]
	
	return (
		<Card
			elevation={0}
			key={index}
			sx={{ width: "100%", backgroundColor: "none", pl: 0, pr: 0 }}
		>
			<Box
				sx={{
					display: "flex",
					width: "100%",
					flexDirection: "row",
					gap: 2,
					mt: 2,
					alignItems: "center",
				}}
			>
				<SortableList.DragHandle />

				<Autocomplete
					multiple
					limitTags={3}
					id="multiple-limit-tags"
					options={pages}
					size="small"
					getOptionLabel={(option) =>
						option?.page ? capitalise(option.page) : option?.api
					}
					value={
						permissionObject[index]?.page
							? permissionObject[index]?.page?.map((p) =>
									pages?.find((i) => i.dbName === p || i?.page === p)
							  )
							: permissionObject[index]?.api?.map((p) =>
									pages?.find((i) => i.dbName === p || i?.page === p)
							  )
					}
					onChange={(e, data) =>
						handleEditRule(
							data?.map((v) => v?.dbName),
							index
						)
					}
					renderInput={(params) => <TextField {...params} label="Pages" />}
					// sx={{ width: 350 }}
					sx={{
						width: 350,
						"& .MuiOutlinedInput-root": {
							paddingRight: "10px!important",
						},
					}}
				/>
				<FormControl sx={{ minWidth: 120 }} size="small">
					<InputLabel id="demo-select-small-label">Actions</InputLabel>
					<Select
						labelId="demo-select-small-label"
						id="demo-select-small"
						value={permissionObject[index]?.actions}
						label="Actions"
						onChange={(e) => handleEditActions(e, index)}
						multiple
					>
						{
						permissionObject[index]?.page.includes('macros') || permissionObject[index]?.page.includes('analysisCategories')|| permissionObject[index]?.page.includes('pricebands') || permissionObject[index]?.page.includes('modifiers') || permissionObject[index]?.page.includes('paymentMethods') || permissionObject[index]?.page.includes('users') || permissionObject[index]?.page.includes('userTypes') || permissionObject[index]?.page.includes('policies') || permissionObject[index]?.page.includes('configuration') ?
						actions.filter((a)=> a.name !== 'All').map((action) => 
							<MenuItem value={action.value}>{action.name}</MenuItem>)						:
						 actions.map((action) => 
						<MenuItem value={action.value}>{action.name}</MenuItem>
						
)
}
					</Select>
				</FormControl>

				<PermissionsToggle
					// value={checkPermissions2(permissionObject, {
					// 	page: "*",
					// 	api: "*",
					// 	action: permissionObject[i].action,
					// })}
					value={permissionObject[index]?.allow}
					// onChange={handleChange}
					onChange={(e, newValue) => handleEditAllow(e, newValue, index)}
					action="edit"
				/>
				{/* </Collapse> */}
				<IconButton
					color={Colours.gsblue}
					sx={{ justifySelf: "flex-end", color: Colours.gsblue }}
					onClick={(e) => {
						handleRuleDelete(e, index);
					}}
				>
					<DeleteIcon />
				</IconButton>
			</Box>
			<Box
				sx={{ display: "flex", gap: 2, flexDirection: "row", width: "100%" }}
			>
				<Box sx={{ width: "24px" }} />
				<Box sx={{ flex: 1 }}>
					{error?.includes(rule.id) && (
						<Typography sx={{ fontSize: 11, mt: 1, color: Colours.gsblue }}>
							This rule conflicts with a previous rule and may supersede it.
						</Typography>
					)}
				</Box>
			</Box>
		</Card>
	);
}
