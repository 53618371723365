import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { getTextColour } from "../../utils";
import { Colours } from "../../../../assets/global/Theme-variable";

const Root = styled("div")(
	({ theme }) => `
    background-color: none;
display: flex;
flex-direction: row;
flex-wrap: wrap;
  font-size: 14px;
  width:'100%';
`
);

const Tag =({tagRef, colour, label, itemsRef,index, limitTags }) =>{

	return (
        <Box ref={element => {
            if(element) {
              itemsRef.current[index] = element
            } else {
              delete itemsRef.current[index]
            }
          }}>
            {index < limitTags &&
		<Box   key={JSON.stringify(tagRef)} sx={{
            display: 'flex',
			alignItems: 'center',
            justifyContent:'center',
			height: '24px',
			margin: '2px',
			lineHeight: '22px',
			backgroundColor: `${colour}`,
			border: `1px solid ${colour}`,
			borderRadius: '6px',
			boxSizing: 'content-box',
			padding: '2px 6px 2px 6px',
			outline: 0,
			overflow: 'hidden',
			'&:focus': {
			  borderColor: `${colour}`,
			  backgroundColor:`${colour}`
			},
		  
			'& span':{
			  overflow: 'hidden',
			  whiteSpace: 'nowrap',
			  textOverflow: 'ellipsis'
			},
		  
			'& svg': {
			  fontSize: '12px',
			  cursor: 'pointer',
			  padding: '4px',
			  color: 'white'
			}
		  }}>
            
			<Typography
				sx={{
					fontSize: 10,
                    whiteSpace: 'nowrap',

					color:
						colour === "#2a9ec5"
							? "white"
							: getTextColour(colour).colour,
			
                        }}
			>
				{label}
			</Typography>
            
		</Box>
}
        </Box>
	);
}

function getWidths(element, width) {
    // console.log('el', element)

    // console.log('sh', element?.scrollHeight)
    // console.log('sw', element?.scrollWidth)
    // console.log('ch', element?.clientHeight)
    // console.log('cw', element?.clientWidth)
// console.log(value * 60)

	return (
	//   element?.scrollHeight > element?.clientHeight ||
	//   element?.scrollWidth >
       element?.clientWidth
	);
    // return (
    //    ( value * 80) > width)
  }

export default function ExpandingTagCell2({value, width}) {
	let numTags = value?.length;
    const itemsRef = React.useRef([]);
    const wrapper = React.useRef(null);

//     // you can access the elements with itemsRef.current[n]

    // React.useEffect(() => {
    //    itemsRef.current = itemsRef.current.slice(0, value.length);
    // }, []);

// // value.map((item, i)=> console.log(getWidths(itemsRef.current[i])))
// // console.log(cellValue?.children)
// var children = document.getElementById('container')?.children;

function getTags() {
    var totalWidth = width;
let tags2 = []
for (var i = 0; i < itemsRef?.current?.length; i++) {
    // totalWidth += parseInt(children[i]?.offsetWidth, 10);
    console.log(value[i])
   console.log(totalWidth - parseInt(itemsRef.current[i]?.offsetWidth, 10))
    if(value[i]!== undefined && totalWidth - parseInt(itemsRef.current[i]?.offsetWidth, 10) >= 0) {
    tags2.push(value[i])
    }
    totalWidth -= parseInt(itemsRef.current[i]?.offsetWidth, 10)

}
return tags2
}
console.log(getTags())

let limitTags = getTags().length


	return (
        <>
		<Box ref={wrapper} sx={{display:'flex', flexDirection:'row',}}>
			{value
				// ?.slice(0, limitTags)
				.map(
					(option, index) =>
						option !== undefined && (
							<Tag
								label={
									option?.tag_name ? option?.tag_name : option?.policy_name
								}
                                index={index}
                             itemsRef={itemsRef}								tagRef={option}
								key={JSON.stringify(option)}
                                limitTags={limitTags}
								colour={
									option?.tag_details
										? option?.tag_details?.styling?.colour
										: Colours.gsblue
								}
							/>
						)
				)}
		</Box>
        			{numTags > limitTags && ` +${numTags - limitTags}`}
</>
	);
}
